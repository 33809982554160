import React from 'react'
import Layout from '../components/common/Layout'

const Branding = () => {
    return (
        <Layout>
            
        </Layout>
    )
}

export default Branding
